import gql from 'graphql-tag';

export default gql`
  query UpdateProductQuery($productID: ID!) {
    user {
      id
      firstName
      lastName
      fullName
      profile {
        id
        findUniqueProduct(where: { id: $productID }) {
          id
          title
          description
          status
        }
      }
    }
  }
`;
