<template>
  <div class="main-menu pt-4 sticky-top">
    <div class="my-3 pt-5">
      <!-- Profile -->
      <template v-if="user && user.profile">
        <div class="pt-3 pb-2 menu-section-title">Profile</div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="card-text" class="mr-2"></b-icon>Content
        </div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="brush" class="mr-2"></b-icon>Branding
        </div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="wallet" class="mr-2"></b-icon>Payout
        </div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="bar-chart" class="mr-2"></b-icon>Statistics
        </div>
      </template>

      <!-- Content -->
      <div class="pt-3 pb-2 menu-section-title">Content</div>
      <div
        v-if="user"
        class="pb-2 pt-2 text-muted"
        :class="{
          active: $route.name === 'LibraryPage' || $route.name === 'LibraryPage',
        }"
        @click="navigateTo('LibraryPage')"
      >
        <b-icon icon="folder" class="mr-2"></b-icon>Library
      </div>

      <div
        class="pb-2 pt-2 text-muted"
        :class="{
          active: $route.name === 'HomePage' || $route.name === 'HomePage',
        }"
        @click="navigateTo('HomePage')"
      >
        <b-icon icon="house" class="mr-2"></b-icon>Discover
      </div>

      <div class="pb-2 pt-2 text-muted"
        :class="{
          active: $route.name === 'BrowsePage' || $route.name === 'BrowsePage',
        }"
        @click="navigateTo('BrowsePage')"
      >
        <b-icon icon="search" class="mr-2"></b-icon>Browse
      </div>

      <div class="pb-2 pt-2 text-muted"
        :class="{
          active: $route.name === 'BasketPage' || $route.name === 'BasketPage',
        }"
        @click="navigateTo('BasketPage')"
      >
        <b-icon icon="bag" class="mr-2"></b-icon>Basket
      </div>

      <!-- Account -->
      <template v-if="user">
        <div class="pt-3 pb-2 menu-section-title">Account</div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="sliders" class="mr-2"></b-icon>Settings
        </div>
        <div class="pb-2 pt-2 text-muted">
          <b-icon icon="credit-card" class="mr-2"></b-icon>Payment
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultMenu',
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    navigateTo(name) {
      const { $route, $router } = this;
      if ($route.name !== name) {
        $router.push({ name });
      }
    },
  },
};
</script>

<style>
.main-menu .text-muted {
  font-size: 0.88em;
  cursor: pointer;
}
.main-menu .text-muted:hover {
  color: var(--primary-color) !important;
}
.main-menu .text-muted.active {
  color: var(--primary-color) !important;
  font-weight: 500;
}
.main-menu .text-muted.inactive {
  cursor: help !important;
  opacity: .8;
}
.main-menu .text-muted.inactive:hover {
  color: #6c757d !important;
}
.main-menu .menu-section-title {
  font-weight: 600;
}
</style>
