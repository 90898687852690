<template>
  <b-container>
    <top-bar :user="user" />
    <slot></slot>
  </b-container>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/Layout/TopBar.vue';

export default {
  name: 'Layout',
  components: {
    TopBar,
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
