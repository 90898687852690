import ls from 'local-storage';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth } from 'aws-amplify';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/',
    name: 'BrowsePage',
    component: () => import('../views/Product/Browse.vue'),
  },
  {
    path: '/',
    name: 'BasketPage',
    component: () => import('../views/Product/Basket.vue'),
  },

  /*
  / AUTH PAGES
  */
  {
    path: '/signup',
    name: 'SignUpPage',
    meta: { mustNotAuth: true },
    component: () => import('../views/SignUp.vue'),
  },
  {
    path: '/signin',
    name: 'SignInPage',
    meta: { mustNotAuth: true },
    component: () => import('../views/SignIn.vue'),
  },
  {
    path: '/signup-creator',
    name: 'SignUpCreatorPage',
    meta: { mustNotAuth: true },
    component: () => import('../views/SignUpCreator.vue'),
  },
  {
    path: '/signup-verify',
    name: 'SignUpVerifyPage',
    component: () => import('../views/SignUpVerify.vue'),
  },
  {
    path: '/library',
    name: 'LibraryPage',
    meta: { requiresAuth: true },
    component: () => import('../views/Library.vue'),
  },
  {
    path: '/products',
    name: 'ProductsPage',
    props: true,
    component: () => import('../views/Product/Index.vue'),
  },

  /*
  / CREATOR PRODUCT PAGES
  */
  {
    path: '/create-product',
    name: 'CreateProductPage',
    meta: { requiresAuth: true },
    component: () => import('../views/Product/UpsertProduct.vue'),
  },
  {
    path: '/edit-product/:productID',
    name: 'EditProductPage',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/Product/UpsertProduct.vue'),
  },
  {
    path: '/upsert-product-items/:productID',
    name: 'UpsertProductItemsPage',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/Product/UpsertProductItems.vue'),
  },
  {
    path: '/creator-preview/:productID',
    name: 'CreatorPreviewPage',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/Product/CreatorPreview.vue'),
  },
  {
    path: '/creator-statistics/:productID',
    name: 'CreatorStatisticsPage',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/Product/CreatorStatistics.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const mustNotAuth = to.matched.some((record) => record.meta.mustNotAuth);
  const isAuthenticated = await Auth.currentUserInfo();

  /* protect pages that needs to be authenticated */
  if (requiresAuth && !isAuthenticated) {
    next('/');

  /* avoid displaying auth pages for authenticated users */
  } else if (mustNotAuth && isAuthenticated) {
    next({ name: 'LibraryPage' });

  /* user needs to verify their email address */
  } else if (mustNotAuth && !isAuthenticated && ls('unverifiedEmailAddress')) {
    next({ name: 'SignUpVerifyPage' });
    return;
  }

  next();
});

export default router;
