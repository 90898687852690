import gql from 'graphql-tag';

export default gql`
  query LibraryQuery {
    user {
      id
      firstName
      lastName
      fullName
      profile {
        id
        name
        description
        productConnection(orderBy: [{
          createdAt: "desc"
        }]) {
          products {
            id
            title
            description
            status
          }
          total
        }
      }
    }
  }
`;
