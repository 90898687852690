import gql from 'graphql-tag';

export default gql`
  query CurrentUser {
    user {
      id
      firstName
      lastName
      fullName
      profile {
        id
        name
        description
      }
    }
  }
`;
