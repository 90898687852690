export default {
  namespaced: true,
  actions: {
    async bootAuth({ dispatch }) {
      try {
        await dispatch('auth/loadUser', null, { root: true });
        await dispatch('profile/loadProfile', null, { root: true });
        await dispatch('ownedProduct/loadItems', { set: true }, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async unbootAuth({ commit }) {
      try {
        await commit('auth/setUser', null, { root: true });
        await commit('profile/setProfile', null, { root: true });
        await commit('ownedProduct/setItems', null, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
  },
};
