import { API, graphqlOperation } from 'aws-amplify';
import {
  productItemsByProductId as productItemsByProductIDQuery,
  getProductItem as getProductItemQuery,
} from '@/graphql/queries';
import {
  createProductItem as createProductItemMutation,
  updateProductItem as updateProductItemMutation,
  deleteProductItem as deleteProductItemMutation,
} from '@/graphql/mutations';

export default {
  namespaced: true,
  state: {
    item: null,
    items: null,
    nextToken: null,
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    pushItems(state, payload) {
      payload.forEach((el) => {
        state.items.push(el);
      });
    },
    setNextToken(state, payload) {
      state.nextToken = payload;
    },
    setItem(state, payload) {
      state.item = payload;
    },
  },
  actions: {
    async loadItems({ getters, rootGetters, commit }, { set }) {
      try {
        if (rootGetters['ownedProduct/item']) {
          commit('loading/setLoading', true, { root: true });
          const { id: productID } = rootGetters['ownedProduct/item'];
          const {
            data: {
              productItemsByProductID,
            },
          } = await API.graphql(graphqlOperation(productItemsByProductIDQuery, {
            productID,
            limit: 30,
            nextToken: getters.nextToken,
          }));
          if (set) {
            commit('setItems', productItemsByProductID.items);
          } else {
            commit('pushItems', productItemsByProductID.items);
          }
          commit('setNextToken', productItemsByProductID.nextToken);
          commit('loading/setLoading', false, { root: true });
        }
        console.log('OWNED PRODUCT ITEMS: ', getters.items);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async loadItem({ getters, commit }, { item }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const {
          data: {
            getProductItem,
          },
        } = await API.graphql(graphqlOperation(getProductItemQuery, {
          id: item.id,
        }));
        commit('setItem', getProductItem);
        commit('loading/setLoading', false, { root: true });
        console.log('OWNED PRODUCT: ', getters.item);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async createItem({ dispatch, rootGetters, commit }, { item }) {
      try {
        const profile = rootGetters['profile/profile'];
        const product = rootGetters['ownedProduct/item'];
        if (profile && product) {
          commit('loading/setLoading', true, { root: true });
          const input = {
            adminIDs: profile.adminIDs,
            productID: product.id,
            title: item.title,
            description: item.description,
          };
          await API.graphql(graphqlOperation(createProductItemMutation, { input }));
          dispatch('loadItems', { set: true });
          commit('loading/setLoading', false, { root: true });
        }
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async updateItem({ dispatch, commit }, { item, id }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const input = {
          id,
          title: item.title,
          description: item.description,
        };
        await API.graphql(graphqlOperation(updateProductItemMutation, {
          input,
        }));
        dispatch('loadItems', { set: true });
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async deleteItem({ dispatch, commit }, { id }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const input = { id };
        await API.graphql(graphqlOperation(deleteProductItemMutation, {
          input,
        }));
        dispatch('loadItems', { set: true });
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    clearItem({ commit }) {
      commit('setItem', null);
    },
    clearItems({ commit }) {
      commit('setItems', null);
    },
  },
  getters: {
    items: (state) => state.items,
    item: (state) => state.item,
  },
};
