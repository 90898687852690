import gql from 'graphql-tag';

export default gql`
  mutation UpdateProductMutation(
    $title: String!
    $description: String
    $status: ProductStatus
  ) {
    createProduct(
      data: {
        title: $title
        description: $description
        status: $status
      }
    ) {
      id
      title
      description
      status
    }
  }
`;
