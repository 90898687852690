import gql from 'graphql-tag';

export default gql`
  query DiscoveryQuery {
    findManyProducts(
      where: {
        status: ACTIVE
        profile: {
          status: ACTIVE
        }
      }
      orderBy: [{
          createdAt: "desc"
    }]) {
      products {
        id
        title
        description
        profile {
          id
          name
        }
      }
      total
    }
  }
`;
