import { API, graphqlOperation } from 'aws-amplify';
import {
  productsByProfileId as productsByProfileIDQuery,
  getProduct as getProductQuery,
} from '@/graphql/queries';
import {
  createProduct as createProductMutation,
  updateProduct as updateProductMutation,
  deleteProduct as deleteProductMutation,
} from '@/graphql/mutations';

export default {
  namespaced: true,
  state: {
    item: null,
    items: null,
    nextToken: null,
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    pushItems(state, payload) {
      payload.forEach((el) => {
        state.items.push(el);
      });
    },
    setNextToken(state, payload) {
      state.nextToken = payload;
    },
    setItem(state, payload) {
      state.item = payload;
    },
  },
  actions: {
    async loadItems({ getters, rootGetters, commit }, { set }) {
      try {
        if (rootGetters['profile/profile']) {
          commit('loading/setLoading', true, { root: true });
          const { id: profileID } = rootGetters['profile/profile'];
          const {
            data: {
              productsByProfileID,
            },
          } = await API.graphql(graphqlOperation(productsByProfileIDQuery, {
            profileID,
            sortDirection: 'DESC',
            limit: 10,
            nextToken: getters.nextToken,
          }));
          if (set) {
            commit('setItems', productsByProfileID.items);
          } else {
            commit('pushItems', productsByProfileID.items);
          }
          commit('setNextToken', productsByProfileID.nextToken);
          commit('loading/setLoading', false, { root: true });
        }
        console.log('OWNED PRODUCTS: ', getters.items);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async loadItem({ getters, commit }, { item }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const { data: { getProduct } } = await API.graphql(graphqlOperation(getProductQuery, {
          id: item.id,
        }));
        commit('setItem', getProduct);
        commit('loading/setLoading', false, { root: true });
        console.log('OWNED PRODUCT: ', getters.item);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async createItem({ dispatch, rootGetters, commit }, { item }) {
      try {
        const user = rootGetters['auth/user'];
        const profile = rootGetters['profile/profile'];
        if (user && profile) {
          commit('loading/setLoading', true, { root: true });
          const input = {
            adminIDs: profile.adminIDs,
            profileID: profile.id,
            title: item.title,
            description: item.description,
            status: 'UNPUBLISHED',
          };
          await API.graphql(graphqlOperation(createProductMutation, { input }));
          dispatch('loadItems', { set: true });
          commit('loading/setLoading', false, { root: true });
        }
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async updateItem({ dispatch, commit }, { item, id }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const input = {
          id,
          title: item.title,
          description: item.description,
        };
        await API.graphql(graphqlOperation(updateProductMutation, {
          input,
        }));
        dispatch('loadItems', { set: true });
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async deleteItem({ dispatch, commit }, { id }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const input = { id };
        await API.graphql(graphqlOperation(deleteProductMutation, {
          input,
        }));
        dispatch('loadItems', { set: true });
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    clearItem({ commit }) {
      commit('setItem', null);
    },
    clearItems({ commit }) {
      commit('setItems', null);
    },
  },
  getters: {
    items: (state) => state.items,
    item: (state) => state.item,
  },
};
