export default [
  {
    key: 'food',
    displayName: 'Food',
    icon: 'egg-fried',
  }, {
    key: 'sport',
    displayName: 'Sport',
    icon: 'bicycle',
  }, {
    key: 'health',
    displayName: 'Health',
    icon: 'heart',
  }, {
    key: 'fitness',
    displayName: 'Fitness',
    icon: 'droplet',
  }, {
    key: 'fashion',
    displayName: 'Fashion',
    icon: 'watch',
  },
];
