<template>
  <b-row>
    <b-col class="pr-0 pl-3 mx-0 main-menu" md="3" lg="2">
      <slot name="left-menu">
        <default-menu :user="user" />
      </slot>
    </b-col>
    <b-col class="p-md-5" md="9" lg="10">
      <b-overlay class="my-3 py-4" :show="loading" spinner-variant="primary" rounded="sm">
        <b-card class="p-md-4">
          <slot></slot>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import DefaultMenu from '@/components/Layout/DefaultMenu.vue';

export default {
  name: 'WideContentContainer',
  components: { DefaultMenu },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'loading/loading',
    }),
  },
};
</script>

<style>
.main-menu .text-muted {
  font-size: 0.88em;
  cursor: pointer;
}
.main-menu .text-muted:hover {
  color: var(--primary-color) !important;
}
.main-menu .text-muted.active {
  color: var(--primary-color) !important;
  font-weight: 500;
}
.main-menu .menu-section-title {
  font-weight: 600;
}
</style>
