<template>
  <layout>
    <b-row class="my-5 pt-5">
      <b-col class="p-md-5" lg="6" offset-lg="3" md="8" offset-md="2" cols="12">
        <b-card class="p-md-4">
          <div class="logo-font mb-4" style="font-size: 30px">Verify Email Address</div>
          <sign-up-verify/>
        </b-card>
      </b-col>
    </b-row>
  </layout>
</template>

<script>
// @ is an alias to /src
import SignUpVerify from '@/components/SignUpVerify.vue';
import Layout from '@/components/Layout/Layout.vue';

export default {
  name: 'SignUpPage',
  components: {
    SignUpVerify,
    Layout,
  },
};
</script>
