/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstName
      lastName
      fullName
      languages
      basket {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profiles {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProductItem = /* GraphQL */ `
  query GetProductItem($id: ID!) {
    getProductItem(id: $id) {
      id
      adminIDs
      productID
      title
      description
      order
      muxAssetID
      videoLink
      createdAt
      updatedAt
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const listProductItems = /* GraphQL */ `
  query ListProductItems(
    $filter: ModelProductItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminIDs
        productID
        title
        description
        order
        muxAssetID
        videoLink
        createdAt
        updatedAt
        product {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const productItemsByProductId = /* GraphQL */ `
  query ProductItemsByProductId(
    $productID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productItemsByProductID(
      productID: $productID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminIDs
        productID
        title
        description
        order
        muxAssetID
        videoLink
        createdAt
        updatedAt
        product {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      adminIDs
      userBaskets {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profileID
      productItems {
        items {
          id
          adminIDs
          productID
          title
          description
          order
          muxAssetID
          videoLink
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      title
      description
      status
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const productsByProfileId = /* GraphQL */ `
  query ProductsByProfileId(
    $profileID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByProfileID(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      owner
      adminIDs
      admins {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      stripeID
      stripeAccount
      name
      description
      createdAt
      updatedAt
      products {
        items {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
