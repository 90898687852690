<template>
  <div>
    <b-row>
      <b-col>
        <b>Your Products</b>
        <b-button
          class="ml-3 float-right"
          variant="primary"
          :to="{ name: 'CreateProductPage' }"
        >Add New</b-button>
      </b-col>
    </b-row>
    <b-row v-if="productConnection && Array.isArray(productConnection.products)">
      <b-col
        cols="12"
        class="border-top pt-4 mt-4 px-3"
        v-for="product in productConnection.products"
        :key="product.id"
      >
        <div class="pb-2">{{ product.title }}</div>
        <div v-if="product.description" class="text-muted pb-2">{{ product.description }}</div>
        <div v-else class="text-muted"><i>no description</i></div>
        <div>
          <span class="text-muted float-left">{{ product.status }}</span>
          <b-link
            class="float-right ml-3 danger"
            v-b-modal="`delete-modal-${product.id}`"
          >Delete</b-link>
          <b-link
            class="float-right ml-3"
            :to="{ name: 'UpsertProductItemsPage', params: { productID: product.id } }"
          >Content</b-link>
          <b-link
            class="float-right"
            :to="{ name: 'EditProductPage', params: { productID: product.id } }"
          >Edit</b-link>
        </div>
        <b-modal :id="`delete-modal-${product.id}`" title="Delete Product">
          <p class="my-4">Are you sure you want to delete <b>{{ product.title }}</b>?</p>
          <template #modal-footer>
            <b-link
              @click="$bvModal.hide(`delete-modal-${product.id}`)"
            >Cancel</b-link>
            <b-link
              class="ml-3 danger"
              @click="$emit('deleteProduct', product.id)"
            >Delete</b-link>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="p-5 text-center">
        You have no products yet.
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    productConnection: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
