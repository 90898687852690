<template>
  <div>
    <b-row v-if="productConnection && Array.isArray(productConnection.products)">
      <b-col
        cols="12"
        class="pt-4 mt-4 px-3"
        v-for="product in productConnection.products"
        :key="product.id"
      >
      <div class="d-flex">
        <div class="mr-3">
          <b-avatar src="https://placekitten.com/300/300" size="72px"></b-avatar>
        </div>
        <div>
          <div style="font-weight: 600; text-transform: capitalize">{{ product.title }}</div>
          <div class="pb-2 text-muted text-small">by {{ product.profile.name }}</div>
          <div v-if="product.description" class="text-muted pb-2">{{ product.description }}</div>
          <div v-else class="text-muted"><i>no description</i></div>
        </div>
      </div>
        <div>
          <b-link
            class="float-right ml-3"
          >Explore</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="p-5 text-center">
        No Products to discover yet.
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    productConnection: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
