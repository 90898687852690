var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu pt-4 sticky-top"},[_c('div',{staticClass:"my-3 pt-5"},[(_vm.user && _vm.user.profile)?[_c('div',{staticClass:"pt-3 pb-2 menu-section-title"},[_vm._v("Profile")]),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"card-text"}}),_vm._v("Content ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"brush"}}),_vm._v("Branding ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"wallet"}}),_vm._v("Payout ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"bar-chart"}}),_vm._v("Statistics ")],1)]:_vm._e(),_c('div',{staticClass:"pt-3 pb-2 menu-section-title"},[_vm._v("Content")]),(_vm.user)?_c('div',{staticClass:"pb-2 pt-2 text-muted",class:{
        active: _vm.$route.name === 'LibraryPage' || _vm.$route.name === 'LibraryPage',
      },on:{"click":function($event){return _vm.navigateTo('LibraryPage')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"folder"}}),_vm._v("Library ")],1):_vm._e(),_c('div',{staticClass:"pb-2 pt-2 text-muted",class:{
        active: _vm.$route.name === 'HomePage' || _vm.$route.name === 'HomePage',
      },on:{"click":function($event){return _vm.navigateTo('HomePage')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"house"}}),_vm._v("Discover ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted",class:{
        active: _vm.$route.name === 'BrowsePage' || _vm.$route.name === 'BrowsePage',
      },on:{"click":function($event){return _vm.navigateTo('BrowsePage')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"search"}}),_vm._v("Browse ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted",class:{
        active: _vm.$route.name === 'BasketPage' || _vm.$route.name === 'BasketPage',
      },on:{"click":function($event){return _vm.navigateTo('BasketPage')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"bag"}}),_vm._v("Basket ")],1),(_vm.user)?[_c('div',{staticClass:"pt-3 pb-2 menu-section-title"},[_vm._v("Account")]),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"sliders"}}),_vm._v("Settings ")],1),_c('div',{staticClass:"pb-2 pt-2 text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"credit-card"}}),_vm._v("Payment ")],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }