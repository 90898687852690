<template>
  <layout>
    <narrow-content-container>
      <div class="logo-font mb-4" style="font-size: 30px">Sign In</div>
      <sign-in/>
    </narrow-content-container>
  </layout>
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/SignIn.vue';
import Layout from '@/components/Layout/Layout.vue';
import NarrowContentContainer from '@/components/Layout/NarrowContentContainer.vue';

export default {
  name: 'HomePage',
  components: {
    SignIn,
    Layout,
    NarrowContentContainer,
  },
};
</script>
