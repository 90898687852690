<template>
  <b-form @submit.prevent="confirmSignUp">
    <b-alert
      :show="error.show"
      dismissible variant="danger"
      @dismissed="error.show = false"
    >{{ error.message }}</b-alert>
    <b-form-group label="Code">
      <b-form-input
        v-model="code"
        type="text"
        placeholder="Enter confirmation code"
        required
      ></b-form-input>
    </b-form-group>
    <b-button class="mt-2 float-right" type="submit" variant="primary">Submit</b-button>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    email: {
      type: String,
      default: null,
    },
    password: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    code: '',
    error: {
      message: '',
      show: false,
    },
  }),
  methods: {
    ...mapActions({
      confirmSignUpAction: 'auth/confirmSignUp',
      signInAction: 'auth/signIn',
    }),
    async confirmSignUp() {
      try {
        const { email, code, password } = this;
        await this.confirmSignUpAction({ email, code });
        if (password) {
          await this.signInAction({ email, password });
          this.$router.push({ name: 'LibraryPage' });
        } else {
          this.$router.push({ name: 'HomePage' });
        }
      } catch (error) {
        this.error.message = error.message;
        this.error.show = true;
      }
    },
  },
};
</script>
