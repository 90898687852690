import gql from 'graphql-tag';

export default gql`
  query CreateProductQuery {
    user {
      id
      firstName
      lastName
      fullName
    }
  }
`;
