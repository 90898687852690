<template>
  <layout :user="user">
    <wide-content-container>
      <upsert-product-menu :productID="productID" slot="left-menu" />
      <b-row>
        <b-col>
          <b>Items</b>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Hello World
        </b-col>
      </b-row>
    </wide-content-container>
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';
import UpsertProductMenu from '@/components/Layout/UpsertProductMenu.vue';
import UpdateProductQuery from '@/graphql/queries/updateProduct';

export default {
  components: {
    Layout,
    WideContentContainer,
    UpsertProductMenu,
  },
  props: {
    productID: {
      type: String,
      default: null,
    },
  },
  apollo: {
    user: {
      query: UpdateProductQuery,
      variables() {
        const { productID } = this;
        return { productID };
      },
      update(data) {
        const { user } = data;
        return user;
      },
      error(error) {
        this.error.show = true;
        this.error.message = error.message;
        console.error('We\'ve got an error!', error);
      },
      watchLoading(isLoading) {
        this.toggleLoading({ loading: isLoading });
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'loading/toggleLoading',
    }),
  },
};
</script>
