<template>
  <layout :user="user">
    <wide-content-container>
      <upsert-product-menu :productID="productID" slot="left-menu" />
      <b-row>
        <b-col>
          <b>Details</b>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form @submit.prevent="saveProduct">
            <b-alert
              class="mt-2"
              :show="error.show"
              dismissible variant="danger"
              @dismissed="error.show = false"
            >{{ error.message }}</b-alert>
            <b-alert
              class="mt-2"
              :show="success.show"
              dismissible variant="success"
              @dismissed="success.show = false"
            >{{ success.message }}</b-alert>
            <div class="form-input-box">
              <b-form-group label="Title">
                <b-form-input
                  v-model="form.title"
                  type="text"
                  placeholder="Enter product title"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="form-input-box">
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  type="text"
                  placeholder="Enter product description"
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>
            <b-button
              v-if="!productID"
              class="mt-2 float-right"
              type="submit"
              variant="primary"
              :disabled="!form.title"
            >Save</b-button>
            <b-button
              v-else
              class="mt-2 float-right"
              type="submit"
              variant="primary"
              :disabled="!form.title"
            >Update</b-button>
          </b-form>
        </b-col>
      </b-row>
    </wide-content-container>
  </layout>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex';
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';
import UpsertProductMenu from '@/components/Layout/UpsertProductMenu.vue';
import UpdateProductQuery from '@/graphql/queries/updateProduct';
import CreateProductQuery from '@/graphql/queries/createProduct';
import CreateProductMutation from '@/graphql/mutations/createProduct';
import UpdateProductMutation from '@/graphql/mutations/updateProduct';

export default {
  name: 'UpsertProductPage',
  components: {
    Layout,
    WideContentContainer,
    UpsertProductMenu,
  },
  props: {
    productID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        status: 'UNPUBLISHED',
      },
      error: {
        show: false,
        message: '',
      },
      success: {
        show: false,
        message: '',
      },
    };
  },
  apollo: {
    user: {
      query() {
        const { productID } = this;
        return productID ? UpdateProductQuery : CreateProductQuery;
      },
      variables() {
        const { productID } = this;
        if (!productID) {
          return null;
        }
        return { productID };
      },
      update(data) {
        const { user } = data;
        const {
          title,
          description,
          status,
        } = user && user.profile ? user.profile.findUniqueProduct : {};
        this.form = {
          title: title || '',
          description: description || '',
          status: status || 'UNPUBLISHED',
        };
        return data.user;
      },
      error(error) {
        this.error.show = true;
        this.error.message = error.message;
        console.error('We\'ve got an error!', error);
      },
      watchLoading(isLoading) {
        this.toggleLoading({ loading: isLoading });
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'loading/toggleLoading',
    }),
    async saveProduct() {
      this.toggleLoading({ loading: true });
      const { productID, form } = this;
      try {
        if (!productID) {
          const { data } = await this.$apollo.mutate({
            mutation: CreateProductMutation,
            variables: {
              ...form,
            },
          });
          if (data && data.createProduct && data.createProduct.id) {
            await this.$router.push({
              name: 'EditProductPage',
              params: {
                productID: data.createProduct.id,
              },
              query: {
                saved: true,
              },
            });
            this.success.show = true;
            this.success.message = 'Content details have been successfully saved.';
          } else {
            this.error.show = true;
            this.error.message = 'There was an error saving your content details.';
          }
        } else {
          await this.$apollo.mutate({
            mutation: UpdateProductMutation,
            variables: {
              productID,
              ...form,
            },
          });
          this.success.show = true;
          this.success.message = 'Content details have been successfully saved.';
        }
      } catch (error) {
        this.error.show = true;
        this.error.message = error.message;
        console.log(error);
      }
      this.toggleLoading({ loading: false });
      return true;
    },
  },
};
</script>
