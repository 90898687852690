/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:fe63c63a-97fd-424a-96fd-232d6e0a37ba",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_F3s390g70",
    "aws_user_pools_web_client_id": "mkdiala8i6o2uh7lhm79helqf",
    "oauth": {},
    "aws_user_files_s3_bucket": "craftisan0ea1dc5355954f0dbaa061e8bdd8a815151736-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://kypuplrg7rg7zcmgci637hflwi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "apolloserver",
            "endpoint": "https://yxlv5ix9rc.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
