import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth/auth';
import profile from './profile/profile';
import ownedProduct from './product/ownedProduct';
import ownedProductItem from './product/ownedProductItem';
import boot from './boot/boot';
import loading from './global/loading';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    loading,
    boot,
    ownedProduct,
    ownedProductItem,
  },
});
