export default {
  namespaced: true,
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    toggleLoading({ commit, getters: { loading } }) {
      commit('setLoading', !loading);
    },
    setLoading({ commit }, payload) {
      commit('setLoading', payload);
    },
  },
  getters: {
    loading: (state) => state.loading,
  },
};
