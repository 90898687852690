<template>
  <b-row class="my-5 pt-5">
    <b-col class="p-md-5" lg="6" offset-lg="3" md="8" offset-md="2" cols="12">
      <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
        <b-card class="p-md-4">
          <slot></slot>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NarrowContentContainer',
  computed: {
    ...mapGetters({
      loading: 'loading/loading',
    }),
  },
};
</script>
