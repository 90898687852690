/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerUser = /* GraphQL */ `
  mutation RegisterUser {
    registerUser {
      id
      username
      firstName
      lastName
      fullName
      languages
    }
  }
`;
export const getStripeConnectOnboardingLink = /* GraphQL */ `
  mutation GetStripeConnectOnboardingLink(
    $refreshURL: String!
    $returnURL: String!
  ) {
    getStripeConnectOnboardingLink(
      refreshURL: $refreshURL
      returnURL: $returnURL
    )
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      fullName
      languages
      basket {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profiles {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      fullName
      languages
      basket {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profiles {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      fullName
      languages
      basket {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profiles {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserBasket = /* GraphQL */ `
  mutation CreateUserBasket(
    $input: CreateUserBasketInput!
    $condition: ModelUserBasketConditionInput
  ) {
    createUserBasket(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUserBasket = /* GraphQL */ `
  mutation UpdateUserBasket(
    $input: UpdateUserBasketInput!
    $condition: ModelUserBasketConditionInput
  ) {
    updateUserBasket(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUserBasket = /* GraphQL */ `
  mutation DeleteUserBasket(
    $input: DeleteUserBasketInput!
    $condition: ModelUserBasketConditionInput
  ) {
    deleteUserBasket(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      adminIDs
      userBaskets {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profileID
      productItems {
        items {
          id
          adminIDs
          productID
          title
          description
          order
          muxAssetID
          videoLink
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      title
      description
      status
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      adminIDs
      userBaskets {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profileID
      productItems {
        items {
          id
          adminIDs
          productID
          title
          description
          order
          muxAssetID
          videoLink
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      title
      description
      status
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      adminIDs
      userBaskets {
        items {
          id
          userID
          productID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      profileID
      productItems {
        items {
          id
          adminIDs
          productID
          title
          description
          order
          muxAssetID
          videoLink
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      title
      description
      status
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const createProductItem = /* GraphQL */ `
  mutation CreateProductItem(
    $input: CreateProductItemInput!
    $condition: ModelProductItemConditionInput
  ) {
    createProductItem(input: $input, condition: $condition) {
      id
      adminIDs
      productID
      title
      description
      order
      muxAssetID
      videoLink
      createdAt
      updatedAt
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const updateProductItem = /* GraphQL */ `
  mutation UpdateProductItem(
    $input: UpdateProductItemInput!
    $condition: ModelProductItemConditionInput
  ) {
    updateProductItem(input: $input, condition: $condition) {
      id
      adminIDs
      productID
      title
      description
      order
      muxAssetID
      videoLink
      createdAt
      updatedAt
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const deleteProductItem = /* GraphQL */ `
  mutation DeleteProductItem(
    $input: DeleteProductItemInput!
    $condition: ModelProductItemConditionInput
  ) {
    deleteProductItem(input: $input, condition: $condition) {
      id
      adminIDs
      productID
      title
      description
      order
      muxAssetID
      videoLink
      createdAt
      updatedAt
      product {
        id
        adminIDs
        userBaskets {
          nextToken
        }
        profileID
        productItems {
          nextToken
        }
        title
        description
        status
        createdAt
        updatedAt
        owner
        profile {
          id
          owner
          adminIDs
          stripeID
          stripeAccount
          name
          description
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const createProfileAdmin = /* GraphQL */ `
  mutation CreateProfileAdmin(
    $input: CreateProfileAdminInput!
    $condition: ModelProfileAdminConditionInput
  ) {
    createProfileAdmin(input: $input, condition: $condition) {
      id
      userID
      profileID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateProfileAdmin = /* GraphQL */ `
  mutation UpdateProfileAdmin(
    $input: UpdateProfileAdminInput!
    $condition: ModelProfileAdminConditionInput
  ) {
    updateProfileAdmin(input: $input, condition: $condition) {
      id
      userID
      profileID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteProfileAdmin = /* GraphQL */ `
  mutation DeleteProfileAdmin(
    $input: DeleteProfileAdminInput!
    $condition: ModelProfileAdminConditionInput
  ) {
    deleteProfileAdmin(input: $input, condition: $condition) {
      id
      userID
      profileID
      user {
        id
        username
        firstName
        lastName
        fullName
        languages
        basket {
          nextToken
        }
        profiles {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      profile {
        id
        owner
        adminIDs
        admins {
          nextToken
        }
        stripeID
        stripeAccount
        name
        description
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      owner
      adminIDs
      admins {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      stripeID
      stripeAccount
      name
      description
      createdAt
      updatedAt
      products {
        items {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      owner
      adminIDs
      admins {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      stripeID
      stripeAccount
      name
      description
      createdAt
      updatedAt
      products {
        items {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      owner
      adminIDs
      admins {
        items {
          id
          userID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      stripeID
      stripeAccount
      name
      description
      createdAt
      updatedAt
      products {
        items {
          id
          adminIDs
          profileID
          title
          description
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
