<template>
  <b-navbar
    class="d-flex justify-content-between align-items-center bg-white"
    fixed="top"
  >
    <b-navbar-brand
    class="logo-font"
    style="font-size: 22px; cursor: pointer"
    @click="navigateTo('HomePage')"
    >Craftisan</b-navbar-brand>
    <b-navbar-nav v-if="!user">
      <b-nav-item @click="navigateTo('SignInPage')">Sign In</b-nav-item>
      <b-nav-item @click="navigateTo('SignUpPage')">Sign Up</b-nav-item>
      <b-nav-item @click="navigateTo('SignUpCreatorPage')">Creator</b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav v-else>
      <b-nav-item>Hello {{user.fullName}}</b-nav-item>
      <b-nav-item @click="signOut">Sign Out</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut',
    }),
    navigateTo(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
    async signOut() {
      try {
        await this.signOutAction();
        this.$apollo.provider.defaultClient.cache.reset();
        this.$router.push({ name: 'HomePage' });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
