<template>
  <div>Hello World!</div>
</template>

<script>
import gql from 'graphql-tag';

const findManyProducts = gql`
  query {
    findManyProducts {
      products {
        id
        title
        description
        profile {
          id
          name
          stripeID
        }
      }
      total
    }
  }
`;

export default {
  apollo: {
    products: {
      query: () => findManyProducts,
      update: (data) => data.findManyProducts,
    },
  },
  watch: {
    products() {
      console.log('Products: ', this.products);
    },
  },
};
</script>
