<template>
  <div class="main-menu pt-4 sticky-top">
    <div class="my-3 pt-5">

      <!-- Go Back Button -->
      <div
        class="pb-3 pt-2 text-muted border-bottom"
        @click="$router.push({ name: 'LibraryPage' })"
      >
        <b-icon icon="arrow-left" class="mr-2"></b-icon>Back to All
      </div>

      <!-- Menu -->
      <div class="pt-3 pb-2 menu-section-title">Manage Content</div>

      <!-- Details -->
      <div
        class="pb-2 pt-2 text-muted"
        :class="{
          active: $route.name === 'EditProductPage' || $route.name === 'CreateProductPage',
        }"
        @click="navigateTo('EditProductPage')"
      >
        <b-icon icon="pen" class="mr-2"></b-icon>Details
      </div>

      <!-- Items -->
      <div
        v-if="productID"
        class="pb-2 pt-2 text-muted"
        :class="{ active: $route.name === 'UpsertProductItemsPage' }"
        @click="navigateTo('UpsertProductItemsPage')"
      >
        <b-icon icon="collection" class="mr-2"></b-icon>Items
      </div>
      <div
        v-else class="pb-2 pt-2 text-muted inactive"
        v-b-tooltip.hover="{ variant: 'info', placement: 'top' }"
        title="Save content details first to manage items"
      >
        <b-icon icon="collection" class="mr-2"></b-icon>Items
      </div>

      <!-- Preview -->
      <div
        v-if="productID"
        class="pb-2 pt-2 text-muted"
        :class="{ active: $route.name === 'CreatorPreviewPage' }"
        @click="navigateTo('CreatorPreviewPage')"
      >
        <b-icon icon="eye" class="mr-2"></b-icon>Preview
      </div>
      <div
        v-else
        class="pb-2 pt-2 text-muted inactive"
        v-b-tooltip.hover="{ variant: 'info', placement: 'top' }"
        title="Save content details first to see preview"
      >
        <b-icon icon="eye" class="mr-2"></b-icon>Preview
      </div>

      <!-- Statistics -->
      <div
        v-if="productID"
        class="pb-2 pt-2 text-muted"
        :class="{ active: $route.name === 'CreatorStatisticsPage' }"
        @click="navigateTo('CreatorStatisticsPage')"
      >
        <b-icon icon="bar-chart" class="mr-2"></b-icon>Statistics
      </div>
      <div
        v-else
        class="pb-2 pt-2 text-muted inactive"
        v-b-tooltip.hover="{ variant: 'info', placement: 'top' }"
        title="Save content details first to see statistics"
      >
        <b-icon icon="bar-chart" class="mr-2"></b-icon>Statistics
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'UpsertProductMenu',
  props: {
    productID: {
      type: String,
      default: null,
    },
  },
  methods: {
    navigateTo(name) {
      const { productID, $route, $router } = this;
      if ($route.name !== name) {
        $router.push({
          name,
          params: {
            productID,
          },
        });
      }
    },
  },
};
</script>
