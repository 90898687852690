import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Amplify, { Auth } from 'aws-amplify';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { concat } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import '@aws-amplify/ui-vue';
import awsExports from './aws-exports';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Amplify.configure(awsExports);

Vue.use(VueApollo);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Create an http link:
const link = new HttpLink({
  uri: 'https://yxlv5ix9rc.execute-api.eu-west-2.amazonaws.com/dev/graphql',
  fetch,
});
const authMiddleware = setContext(async (_, { headers: oldHeaders }) => {
  let headers = oldHeaders;
  try {
    const currentSession = await Auth.currentSession();
    if (currentSession && currentSession.getAccessToken) {
      const { jwtToken } = currentSession.getAccessToken();
      headers = {
        ...headers,
        authorization: jwtToken,
      };
    }
    return { headers };
  } catch (error) {
    return { headers };
  }
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
