import ls from 'local-storage';
import { Auth } from 'aws-amplify';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async signOut({ commit, dispatch }) {
      try {
        commit('loading/setLoading', true, { root: true });
        await Auth.signOut();
        dispatch('boot/unbootAuth', null, { root: true });
        commit('loading/setLoading', false, { root: true });
        Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        Promise.reject(error);
      }
    },
    async signIn({ commit }, { email, password }) {
      try {
        commit('loading/setLoading', true, { root: true });
        await Auth.signIn(email, password);
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        if (error && error.code && error.code === 'UserNotConfirmedException') {
          ls('unverifiedEmailAddress', email);
        }
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(Array.isArray(error.errors) ? error.errors[0] : error);
      }
    },
    async confirmSignUp({ commit }, { email: emailParam, code }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const email = emailParam || ls('unverifiedEmailAddress');
        console.log({ email, code });
        await Auth.confirmSignUp(email, code);
        ls.remove('unverifiedEmailAddress');
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async signUp({ commit }, payload) {
      const {
        email,
        password,
        firstName,
        lastName,
        type,
      } = payload;
      try {
        commit('loading/setLoading', true, { root: true });
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            'custom:firstName': firstName,
            'custom:lastName': lastName,
            'custom:fullName': `${firstName} ${lastName}`,
            'custom:type': type,
          },
        });
        ls('unverifiedEmailAddress', email);
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async loadUser({ commit }) {
      try {
        commit('loading/setLoading', true, { root: true });
        const user = await Auth.currentUserInfo();
        commit('setUser', user);
        commit('loading/setLoading', false, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
  },
  getters: {
    user: (state) => state.user,
  },
};
