<template>
  <layout>
    <wide-content-container>
      <b-row>
        <b-col>
          <h3>Browse</h3>
          <product-list :productConnection="productConnection" />
        </b-col>
      </b-row>
    </wide-content-container>
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import categories from '@/config/categories';
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';
import ProductList from '@/components/ProductLists/Discovery.vue';
import DiscoveryQuery from '@/graphql/queries/discovery';

export default {
  components: {
    Layout,
    WideContentContainer,
    ProductList,
  },
  data: () => ({
    categories,
  }),
  apollo: {
    productConnection: {
      query: DiscoveryQuery,
      update(data) {
        return data.findManyProducts;
      },
      error(error) {
        console.error('We\'ve got an error!', error);
      },
      watchLoading(isLoading) {
        this.toggleLoading({ loading: isLoading });
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'loading/toggleLoading',
    }),
  },
};
</script>
