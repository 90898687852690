<template>
  <!--
    Continue here with making boxes prettier and showing progress of each step
    Add restart onboarding button that deletes Stripe account
    AAAAAAAAAND ANA LOVEEEES YOUUUUU !!!!!!
  -->
  <layout :user="user">
    <wide-content-container v-if="user && !user.profile" :user="user">
      <p>Hello World Signed In!</p>
      <p><code>{{user}}</code></p>
    </wide-content-container>
    <wide-content-container v-else-if="user && user.profile" :user="user">
      <creator-product-list
        :productConnection="user.profile.productConnection"
        @deleteProduct="deleteProduct($event)"
      />
    </wide-content-container>
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import LibraryQuery from '@/graphql/queries/library';
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';
import CreatorProductList from '@/components/Library/CreatorProductList.vue';
import DeleteProductMutation from '@/graphql/mutations/deleteProduct';

export default {
  components: {
    Layout,
    WideContentContainer,
    CreatorProductList,
  },
  data() {
    return {
      profile: null,
    };
  },
  apollo: {
    user: {
      query: LibraryQuery,
      update(data) {
        console.log(data);
        return data.user;
      },
      error(error) {
        console.error('We\'ve got an error!', error);
      },
      watchLoading(isLoading) {
        this.toggleLoading({ loading: isLoading });
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'loading/toggleLoading',
    }),
    async deleteProduct(productID) {
      await this.$apollo.mutate({
        mutation: DeleteProductMutation,
        variables: { productID },
        update: (store, { data: { deleteProduct } }) => {
          const data = store.readQuery({
            query: LibraryQuery,
          });
          const { user } = data || {};
          const { profile } = user || {};
          const { productConnection } = profile || {};
          if (deleteProduct && productConnection && Array.isArray(productConnection.products)) {
            const { products } = productConnection;
            data.user.profile.productConnection.products = products
              .filter((el) => el.id !== productID);
            const { total } = data.user.profile.productConnection;
            data.user.profile.productConnection.total = total - 1;
            store.writeQuery({
              query: LibraryQuery,
              data,
            });
          }
        },
      });
      this.$bvModal.hide(`delete-modal-${productID}`);
    },
  },
};
</script>
