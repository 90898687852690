<template>
  <layout :user="user">
    <wide-content-container :user="user">
      <b-row class="border-bottom pb-4">
        <b-col>
          <h3 class="mb-2">👋 Welcome to Craftisan</h3>
          <p class="text-muted">Quality content by your favourite content creators in one place.</p>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-5">
        <b-col>
          <h3>What are you looking for?</h3>
          <div>
            <span
              class="mt-3 mr-3 py-2 px-4 category-tag"
              v-for="category in categories"
              :key="category.key"
            >
              <b-icon :icon="category.icon" class="mr-2"></b-icon>{{ category.displayName }}
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row class="py-5">
        <b-col>
          <h3>Discover</h3>
          <product-list :productConnection="productConnection" />
        </b-col>
      </b-row>
    </wide-content-container>
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import categories from '@/config/categories';
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';
import ProductList from '@/components/ProductLists/Discovery.vue';
import DiscoveryQuery from '@/graphql/queries/discovery';
import CurrentUserQuery from '@/graphql/queries/currentUser';

export default {
  components: {
    Layout,
    WideContentContainer,
    ProductList,
  },
  data: () => ({
    categories,
  }),
  apollo: {
    productConnection: {
      query: DiscoveryQuery,
      update(data) {
        return data.findManyProducts;
      },
      error(error) {
        console.error('We\'ve got an error!', error);
      },
      watchLoading(isLoading) {
        this.toggleLoading({ loading: isLoading });
      },
      fetchPolicy: 'cache-and-network',
    },
    user: {
      query: CurrentUserQuery,
      update: ({ user }) => user,
      error(error) {
        console.error('We\'ve got an error!', error);
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'loading/toggleLoading',
    }),
  },
};
</script>

<style scoped>
  .category-tag {
    display: inline-block;
    border: 1px solid #666;
    border-radius: 6px;
    cursor: pointer;
  }
</style>
