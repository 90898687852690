<template>
  <b-form v-if="!isSignupVerify" @submit.prevent="signUp">
    <b-alert
      :show="error.show"
      dismissible variant="danger"
      @dismissed="error.show = false"
    >{{ error.message }}</b-alert>
    <div class="form-input-box">
      <b-form-group label="Email">
        <b-form-input
          v-model="email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="form-input-box">
      <b-form-group label="Password">
        <b-form-input
          v-model="password"
          type="password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="form-input-box">
      <b-form-group label="Confirm Password">
        <b-form-input
          v-model="passwordConfirmation"
          type="password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="form-input-box">
      <b-form-group label="First Name">
        <b-form-input
          v-model="firstName"
          type="text"
          placeholder="Enter your first name"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="form-input-box">
      <b-form-group label="Last Name">
        <b-form-input
          v-model="lastName"
          type="text"
          placeholder="Enter your last name"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <b-button class="mt-2 float-right" type="submit" variant="primary">Submit</b-button>
  </b-form>
  <sign-up-verify :email="email" :password="password" v-else />
</template>

<script>
import { mapActions } from 'vuex';
import SignUpVerify from '@/components/SignUpVerify.vue';

export default {
  components: { SignUpVerify },
  props: {
    type: {
      type: String,
      default: 'user',
    },
  },
  data: () => ({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    passwordConfirmation: '',
    isSignupVerify: false,
    code: '',
    error: {
      message: '',
      show: false,
    },
  }),
  methods: {
    ...mapActions({
      signUpAction: 'auth/signUp',
      confirmSignUpAction: 'auth/confirmSignUp',
      signInAction: 'auth/signIn',
    }),
    async signUp() {
      try {
        const {
          email,
          password,
          passwordConfirmation,
          firstName,
          lastName,
          type,
        } = this;
        if (!email) {
          throw new Error('Please provide a valid email address.');
        }
        if (!firstName || !lastName) {
          throw new Error('Please add your name.');
        }
        if (!password || password !== passwordConfirmation) {
          throw new Error('Password confirmation needs to match with password.');
        }
        await this.signUpAction({
          email,
          password,
          firstName,
          lastName,
          type,
        });
        this.isSignupVerify = true;
      } catch (error) {
        this.error.message = error.message;
        this.error.show = true;
      }
    },
  },
};
</script>
