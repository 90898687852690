<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {

};
</script>

<style>
.modal,
#app {
  font-family: 'Poppins', sans-serif;
  color: #444444;
  --primary-color: #2F2F8B;
  --secondary-color: #09ACCD;
  --danger-color: #dc3545;
  --background-color: #f3f7fc;
}

.modal .btn-primary,
#app .btn-primary {
    color: #fff;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.modal .btn,
#app .btn {
  font-weight: 500;
  padding: 0.650rem 2.450rem;
  font-size: 0.9rem;
    border-radius: 6px;
}

.modal .form-control,
#app .form-control {
  width: 100%;
  height: calc(1.8em + 1rem + 2px);
  font-size: 0.9rem;
  padding: 0.600rem 0.850rem;
  border: 0px solid #ced4da;
  border-radius: 0.1rem;
  background: var(--background-color);
  border-radius: 6px;
}
.modal .form-control::placeholder,
#app .form-control::placeholder {
  color: #b4b4c4;
}

.modal .col-form-label,
#app .col-form-label {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2;
}

.modal .card,
#app .card {
    border-radius: 0.1rem;
}

.modal .form-input-box,
#app .form-input-box {
  margin-top: 1.3rem;
}

#app .logo-font {
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.modal .text-primary,
#app .text-primary {
  color: var(--primary-color) !important;
}

.modal a,
#app a {
  color: var(--primary-color);
}

.modal a.danger,
#app a.danger {
  color: var(--danger-color);
}

#app .card {
  border: none;
  border-radius: 16px;
  box-shadow: 0px 12px 16px rgba(0,0,0,.06);
}

#app .navbar {
  box-shadow: 0px 0px 16px rgba(0,0,0,.06);
}

#app .text-small {
  font-size: 0.8rem;
}

</style>
