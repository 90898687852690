<template>
  <b-form v-if="!isSignupVerify" @submit.prevent="signIn">
    <b-alert
      :show="error.show"
      dismissible variant="danger"
      @dismissed="error.show = false"
    >{{ error.message }}</b-alert>
    <div class="form-input-box">
      <b-form-group label="Email">
        <b-form-input
          v-model="email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="form-input-box">
      <b-form-group label="Password">
        <b-form-input
          v-model="password"
          type="password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>
      <b-button class="mt-2 float-right" type="submit" variant="primary">Submit</b-button>
    </div>
  </b-form>
  <sign-up-verify :email="email" :password="password" v-else />
</template>

<script>
import { mapActions } from 'vuex';
import SignUpVerify from '@/components/SignUpVerify.vue';

export default {
  components: { SignUpVerify },
  data: () => ({
    email: '',
    password: '',
    isSignupVerify: false,
    error: {
      message: '',
      show: false,
    },
  }),
  methods: {
    ...mapActions({
      signInAction: 'auth/signIn',
    }),
    async signIn() {
      try {
        const { email, password } = this;
        await this.signInAction({ email, password });
        this.$router.push({ name: 'LibraryPage' });
      } catch (error) {
        if (error && error.code && error.code === 'UserNotConfirmedException') {
          this.isSignupVerify = true;
        }
        this.error.message = error.message;
        this.error.show = true;
      }
    },
  },
};
</script>
