<template>
  <layout>
    <wide-content-container>
      <b-row>
        <b-col>
          <h3>Basket</h3>
          <p>Coming soon...</p>
        </b-col>
      </b-row>
    </wide-content-container>
  </layout>
</template>

<script>
import Layout from '@/components/Layout/Layout.vue';
import WideContentContainer from '@/components/Layout/WideContentContainer.vue';

export default {
  components: {
    Layout,
    WideContentContainer,
  },
};
</script>
