<template>
  <layout>
    <narrow-content-container>
      <div class="logo-font mb-4" style="font-size: 30px">Sign Up</div>
      <sign-up/>
    </narrow-content-container>
  </layout>
</template>

<script>
// @ is an alias to /src
import SignUp from '@/components/SignUp.vue';
import Layout from '@/components/Layout/Layout.vue';
import NarrowContentContainer from '@/components/Layout/NarrowContentContainer.vue';

export default {
  name: 'SignUpPage',
  components: {
    SignUp,
    Layout,
    NarrowContentContainer,
  },
};
</script>
