import { API, graphqlOperation } from 'aws-amplify';
import { getProfile as getProfileQuery } from '@/graphql/queries';
import { getStripeConnectOnboardingLink as getStripeConnectOnboardingLinkMutation } from '@/graphql/mutations';

export default {
  namespaced: true,
  state: {
    profile: null,
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    async loadProfile({ rootGetters, getters, commit }) {
      try {
        if (rootGetters['auth/user']) {
          commit('loading/setLoading', true, { root: true });
          const { profiles } = rootGetters['auth/user'];
          let hasProfile = profiles && Array.isArray(profiles.items) && profiles.items.length;
          hasProfile = hasProfile && profiles.items[0] && profiles.items[0].profileID;
          if (hasProfile) {
            const { profileID: id } = profiles.items[0];
            const profileResp = await API.graphql(graphqlOperation(getProfileQuery, { id }));
            const { getProfile: profile } = profileResp.data;
            commit('setProfile', profile);
          }
          console.log('PROFILE: ', getters.profile);
          commit('loading/setLoading', false, { root: true });
        }
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        commit('loading/setLoading', false, { root: true });
        return Promise.reject(error);
      }
    },
    async retrieveOnboardingLink({ commit }) {
      commit('loading/setLoading', true, { root: true });
      const { data } = await API.graphql(
        /* eslint-disable */
        graphqlOperation(getStripeConnectOnboardingLinkMutation, {
          refreshURL: `${window.location.origin}/library`,
          returnURL: `${window.location.origin}/library`,
        })
        /* eslint-enable */
      );
      commit('loading/setLoading', false, { root: true });
      if (data && data.getStripeConnectOnboardingLink) {
        return JSON.parse(data.getStripeConnectOnboardingLink);
      }
      return null;
    },
  },
  getters: {
    profile: (state) => state.profile,
  },
};
